<template>
    <div class="default-layout">
        <app-header />
        <slot />
        <speach-manager v-if="!isTouchDevice" class="pos-fix" style="right: 0; bottom: 0;" />
        <snack-bar />
    </div>
</template>

<script>
import AppHeader from '@/components/template/AppHeader.vue'
import SnackBar from '@/components/template/SnackBar.vue'
import SpeachManager from '@/components/speachManager'
import { useDisplay } from 'vuetify'

export default {
    name: 'DefaultLayout',
    components: { AppHeader, SpeachManager, SnackBar },
    setup () {
        const isTouchDevice = useDisplay()?.platform?.value.touch
        return {
            isTouchDevice
        }
    }
}
</script>

<style scoped lang="sass">
.default-layout
    width: 100%
    min-height: 100vh
    display: flex
    flex-direction: column
    background: #f9f9f9
    position: relative
</style>