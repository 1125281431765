<template>
  <div v-if="keyCommand && hasSubcommands" class="speech-manager--component">
    
    <speech-chip
        v-if="!isProcessing"
        :command="keyCommand"
        :flat="smAndDown"
        show-microphone
        :disabled="!ready"
        class="ma-5"
        @click="() => simulateCommand(keyCommand)"
    />

    <v-card v-else class="d-flex flex-column pa-5" :width="smAndDown ? 300 : 400">
        <v-icon class="text-disabled">mdi-microphone</v-icon>
        <div class="d-flex justify-end flex-wrap">
            <speech-chip
                history
                :command="keyCommand"
                class="mt-4 ml-4"
            />
            <speech-chip
                v-for="command in commandsHistory"
                :key="command"
                history
                :command="command"
                class="mt-4 ml-4"
            />
        </div>
        <v-divider class="mt-4" />
        <span class="text-caption text-disabled mt-1">
            Ожидание команд 
            <span v-if="timeBeforeProcessingCancel < 10">({{ timeBeforeProcessingCancel }})</span>
        </span>
        <div class="d-flex justify-end flex-wrap">
            <speech-chip
                v-for="command in availableCommands"
                :key="command.key"
                :command="command.key"
                :label="command.label"
                class="mt-4 ml-4"
                @click="() => simulateCommand(command.key)"
            />
        </div>
    </v-card>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify/lib/framework.mjs'
import { ref, computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { key } from '@/plugins/store'
import Timer from '@/helpers/classes/Timer'

import SpeechChip from './SpeechChip.vue'

export default {
    name: 'SpeachManager',
    components: { SpeechChip },
    setup () {

        // Computed
        const hasSubcommands = computed(() => !!speechManager.commandChain?.length)
        const maxProcessingTime = computed(() => store.state.speech_manager.maxProcessingTime)

        // Variables
        const displayParams = useDisplay()
        const store = useStore(key)
        const speechManager = store.state.speech_manager.instance
        const isProcessing = ref(false)
        const keyCommand = ref(null)
        const availableCommands = ref([])
        const commandsHistory = ref([])
        const timer = new Timer(maxProcessingTime.value * 1000)
        const timeBeforeProcessingCancel = ref(0)
        const ready = ref(false)

        // Methods
        const timerStartFunction = timer.start.bind(timer)
        const timerStopFunction = timer.stop.bind(timer)

        const getSpeechManagerState = () => {
            timeBeforeProcessingCancel.value = maxProcessingTime.value
            timer.updateMaxTime(maxProcessingTime.value * 1000)
            isProcessing.value = speechManager.isProcessing
            keyCommand.value = speechManager.keyCommand
            availableCommands.value = speechManager.availableCommands
            commandsHistory.value = speechManager.commandsHistory
        }

        const onTimerUpdatedHandler = () => {
            timeBeforeProcessingCancel.value = timer.currentTime / 1000
            timeBeforeProcessingCancel.value <= 0 && store.dispatch('speech_manager/cancelCommandChain')
        }

        const playBeepSound = () => {
            
            const ctx = new AudioContext()

            const volume = 0.2
            const frequency = 150
            const oscillation_type = 'sine'
            const duration = 0.05

            const oscillator = ctx.createOscillator()
            const gain = ctx.createGain()

            oscillator.connect(gain)
            oscillator.frequency.value = frequency
            oscillator.type = oscillation_type

            gain.connect(ctx.destination)
            
            gain.gain.value = volume
                    
            oscillator.start(ctx.currentTime)
            oscillator.stop(ctx.currentTime + duration)
        }

        const simulateCommand = (value) => {
            if (value === '*') return
            store.state.speech_manager.instance?.simulateTranscription?.(value)
        }

        // Events subscription
        onBeforeUnmount(() => {
            speechManager.off('commandDetected', getSpeechManagerState)
            speechManager.off('commandDetected', playBeepSound)
            speechManager.off('onKeyCommandSet', getSpeechManagerState)
            speechManager.off('onCommandsSet', getSpeechManagerState)
            speechManager.off('commandChainCanceled', getSpeechManagerState)
            speechManager.off('commandDetected', timerStartFunction)
            speechManager.off('commandChainCanceled', timerStopFunction)
            timer.off('update', onTimerUpdatedHandler)
            timer.off('finish', onTimerUpdatedHandler)
        })

        const init = () => {

            speechManager.on('commandDetected', getSpeechManagerState)
            speechManager.on('commandDetected', playBeepSound)
            speechManager.on('onKeyCommandSet', getSpeechManagerState)
            speechManager.on('onCommandsSet', getSpeechManagerState)
            speechManager.on('commandChainCanceled', getSpeechManagerState)
            
            speechManager.on('commandDetected', timerStartFunction)
            speechManager.on('commandChainCanceled', timerStopFunction)
            
            timer.on('update', onTimerUpdatedHandler)
            timer.on('finish', onTimerUpdatedHandler)

            getSpeechManagerState()
        }
        
        // Main processing
        ready.value && init()

        navigator.permissions.query({ name: 'microphone' })
            .then((permissionStatus) => {
                
                ready.value = permissionStatus.state === 'granted' // granted, denied, prompt

                ready.value && init()

                permissionStatus.onchange = () => {
                    ready.value = permissionStatus.state === 'granted'
                    ready.value && init()
                }
            })
        
        
        return {
            smAndDown: displayParams.smAndDown,
            hasSubcommands,
            isProcessing,
            keyCommand,
            availableCommands,
            commandsHistory,
            timeBeforeProcessingCancel,
            ready,
            simulateCommand
        }
    }
}
</script>

<style>

</style>