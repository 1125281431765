import SpeechToCommand from '@/helpers/classes/SpeechToCommand.js'

// TODO: похоже что от реализации голосовых команд через vuex нет смысла.
// Достаточно будет просто сделать этот объект глобальным и доступным через корневой vue instance.
// Лучше переписать именно так
const defaultMaxProcessingTime = 20
export default {
    namespaced: true,
    state: {
        instance: (new SpeechToCommand()),
        maxProcessingTime: defaultMaxProcessingTime,
        defaultMaxProcessingTime: defaultMaxProcessingTime
    },
    mutations: {
        setValue (state, [field, value]) {
            state[field] = value
        }
    },
    actions: {
        init ({ state }, keyCommand) {
            state.instance.setKeyCommand(keyCommand)
            state.instance.stop()
            state.instance.start()
        },
        updateMaxProcessingTime({ commit }, value) {
            commit('setValue', ['maxProcessingTime', parseInt(value)])
        },
        addCommands ({ state }, commands) {
            state.instance.addCommands(commands)
        },
        removeRootCommand ({ state }, command) {
            state.instance.removeRootCommand(command)
        },
        cancelCommandChain ({ state }) {
            state.instance.cancelCommandChain()
        }
    }
}