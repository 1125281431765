export default {
    namespaced: true,
    state: {
        active: false,
        text: null,
        timeout: -1,
        type: 'default' // default | error | speech-synthesis
    },
    mutations: {
        setValue (state, [field, value]) {
            state[field] = value
        }
    },
    actions: {
        show ({ commit }, { text, type = 'default', timeout = -1 }) {
            commit('setValue', ['active', true])
            commit('setValue', ['text', text])
            commit('setValue', ['type', type])
            commit('setValue', ['timeout', timeout])
        },
        hide ({ commit }) {
            commit('setValue', ['active', false])
            commit('setValue', ['text', null])
            commit('setValue', ['timeout', -1])
            commit('setValue', ['type', 'default'])
        }
    }
}