<template>
    <v-snackbar
        v-model="value"
        :key="`snack_bar_timout_${timeout}`"
        variant="flat"
        :timeout="timeout"
        :timer="timeout > 0"
        :color="type === 'error' ? 'red' : colors.colorShikiBlack"
        @update:modelValue="onValueUpdated"
    >
        <div class="d-flex align-center">
            <span>{{ text }}</span>
            <v-spacer />
            <v-btn
                icon="mdi-close"
                color="black"
                size="2.5em"
                class="ml-2"
                @click="closeSnackBar"
            ></v-btn>
        </div>
    </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'
import { store } from '@/plugins/store'
import colors from '@/assets/styles/variables.js'

export default {
    name: 'SnackBar',
    data () {
        return {
            value: false
        }
    },
    computed: {
        ...mapState('snack_bar', ['active', 'text', 'type', 'timeout']),
        colors () { return colors }
    },
    watch: {
        active () {
            this.value = this.active
        }
    },
    methods: {
        closeSnackBar () {
            store.dispatch('snack_bar/hide')
        },
        onValueUpdated () {
            if (this.value !== this.active)
                store.dispatch(`snack_bar/${this.value ? 'show' : 'hide'}`)
        }
    }
}
</script>

<style>

</style>