import EventEmitter from './EventEmitter'

/**
 * events: started, finalTranscript, interimTranscript
 */
export default class SpeachRecognition extends EventEmitter
{
    defaultOptions = {
        continuous: true,
        interimResults: false,
        maxAlternatives: 1,
        lang: 'ru-RU'
    }

    commandList = {}
    recognition = null
    recognizing = false
    finalTranscript = ''
    interimTranscript = ''

    constructor (options = {}) {
        super()
        this._init(Object.assign({...this.defaultOptions}, options))
    }

    start () {
        if (this.recognizing) return
        this.recognition?.start()
    }

    stop () {
        // Устанавливаем значение индикатора распознавания в значение `false`
        this.recognizing = false
        // Останавливаем распознавание
        this.recognition?.stop()
    }

    restart () {
        this.recognition?.abort()
        this.start()
    }

    _init (options = {}) {
        try {
            const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

            if (!speechRecognition) { throw new Error('Current browser doesnt support speech recognition api.') }

            this.recognition = new speechRecognition()
            // Свойство `continuous` определяет, продолжается ли распознавание речи после получения первого финального результата
            this.recognition.continuous = options.continuous
            // Обработка промежуточных результатов
            this.recognition.interimResults = options.interimResults
            // Максимальное количество альтернатив распознанного слова
            this.recognition.maxAlternatives = options.maxAlternatives
            // Язык
            this.recognition.lang = options.lang

            this.recognition.onstart = (e) => this._onRecognitionStart(e)

            this.recognition.onerror = (e) => this.fire('error', e)

            this.recognition.onend = (e) => this._onRecognitionEnd(e)

            this.recognition.onresult = (e) => this._onRecognitionResult(e)
        } catch (e) {
            console.error(e)
            this.recognition = null
        }
    }

    _onRecognitionStart () {
        // Обнуляем переменную для финального результата
        this.finalTranscript = ''
        // Устанавливаем индикатор распознавания в значение `true`
        this.recognizing = true
        this.fire('started')
    }

    _onRecognitionEnd () {
        // Снова запускаем распознавание, если индикатор имеет значение `true`
        if (!this.recognizing) return
        this.recognition?.start()
    }

    _onRecognitionResult (e) {
        // Промежуточные результаты обновляются на каждом цикле распознавания
        this.finalTranscript = ''
        // Перебираем результаты с того места, на котором остановились в прошлый раз
        for (let i = e.resultIndex; i < e.results.length; i++) {
            // Атрибут `isFinal` является индикатором того, что речь закончилась
            if (e.results[i].isFinal) {
                // Добавляем промежуточные результаты
                this.finalTranscript += e.results[i][0].transcript
                this.fire('finalTranscript', this.finalTranscript)
                this.restart()
            } else {
                // В противном случае, записываем распознанные слова в промежуточный результат
                this.interimTranscript += e.results[i][0].transcript
                this.fire('interimTranscript', this.interimTranscript)
            }
        }
    }
}