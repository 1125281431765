<template>
  <v-chip
        :variant="flat ? 'flat' : (history ? 'tonal' : 'outlined')"
        color="red"
        size="x-large"
        :disabled="disabled"
        class="mt-4 ml-4"
    >
        <v-icon v-if="showMicrophone">mdi-microphone</v-icon>
        <i v-if="command === '*'">{{ label || 'Ваша фраза' }}</i>
        <span v-else>{{ firstLetterToUpperCase(label || command) }}</span>
    </v-chip>
</template>

<script>
import { firstLetterToUpperCase } from '@/helpers/string'

export default {
    name: 'SpeechChip',
    props: {
        command: { type: String, required: true },
        label: { type: String, default: null },
        history: { type: Boolean, default: false },
        showMicrophone: { type: Boolean, default: false },
        flat: { type: Boolean, default: false },
        disabled:  { type: Boolean, default: false }
    },
    setup () {
        return {
            firstLetterToUpperCase
        }
    }
}
</script>

<style>

</style>