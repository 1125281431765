<template>
    <div class="poor-layout">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PoorLayout'
}
</script>

<style scoped lang="sass">
.poor-layout
    width: 100%
    height: 100vh
    display: flex
    flex-direction: column
</style>