<template>
    <header class="d-flex flex-column" :style="{ background: cssVars.colorShikiBlack }">
        <v-container>
            <v-row>
                <v-col class="d-flex align-center">
                    <div class="d-flex align-center w-25">
                        <v-app-bar-nav-icon v-if="smAndDown" variant="text" @click.stop="navDrawer = !navDrawer"></v-app-bar-nav-icon>
                        <template v-else>
                            <router-link
                                v-for="item in navItems"
                                :key="item.to"
                                :to="item.to"
                                class="text-nowrap mr-3"
                                :class="item.extraClasses"
                            >
                                {{ item.title }}
                            </router-link>
                        </template>
                    </div>

                    <div class="d-flex align-center justify-center w-50">
                        <v-btn
                            v-if="isLoggedIn && profile && $route.name !== 'marathon'"
                            :to="{ name: 'marathon' }"
                            append-icon="mdi-run"
                            variant="tonal"
                            :size="smAndDown ? 'small': 'default'"
                        >Марафон</v-btn>
                    </div>

                    <div class="d-flex align-center justify-end w-25" style="height: 40px">
                    
                        <div v-if="loading" style="width: 100px;">
                            <v-progress-linear indeterminate />
                        </div>

                        <template v-else-if="isLoggedIn && profile">
                            <span v-if="!smAndDown" class="mr-5">{{ profile.nickname }}</span>
                            <v-avatar v-if="!smAndDown" class="mr-5" :image="profile.avatar" />
                            <v-icon @click="logout">mdi-logout</v-icon>
                        </template>

                        <v-btn v-else variant="outlined" to="/login">Войти</v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-navigation-drawer
            v-model="navDrawer"
            theme="dark"
            :color="cssVars.colorShikiBlack"
            temporary
        >
            <div style="height: 50px" />
            <v-list-item
                v-for="item in navItems"
                :key="item.to"
                :title="item.title"
                :to="item.to"
            />
        </v-navigation-drawer>
    </header>
</template>

<script>
import { computed, ref, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { key } from '@/plugins/store'
import cssVars from '@/assets/styles/variables'
import { useDisplay } from 'vuetify/lib/framework.mjs'

export default {
    name: 'AppHeader',
    setup () {
        const displayParams = useDisplay()
        const router = useRouter()
        const navDrawer = ref(false)
        const navItems = ref([
        {
          title: 'Anime Look',
          to: '/',
          extraClasses: 'text-h6'
        },
        {
          title: 'О сайте',
          to: '/about'
        }
      ])
        const loading = ref(false)
        const store = useStore(key)
        const isLoggedIn = computed(() => store.getters['user/isLoggedIn'])
        const profile = computed(() => store.state.user.profile)
        const logout = () => {
            store.dispatch('user/clearAccessToken')
            store.dispatch('user/clearProfile')
            router.push({ name: 'login' })
        }

        const refreshAccessTokenIfExpired = async () => {
            let error = null
            // If session is expired
            if (store.getters['user/isLoggedIn'] && store.getters['user/isTokenExpired']) {
                error = await store.dispatch('user/refreshAccessToken')
            }
            return error
        }
        let intervalId = null
        onBeforeUnmount(() => clearInterval(intervalId))
        
        store.dispatch('user/loadTokenInfo')

        ;(async () => {
            loading.value = true
            let error = null
            try {
                error = await refreshAccessTokenIfExpired()

                // On logged in
                if (store.getters['user/isLoggedIn'] && !error) {
                    
                    await store.dispatch('user/fetchProfile')
                    await store.dispatch('rates/fetch')

                    intervalId = setInterval(refreshAccessTokenIfExpired, 1000 * 60)
                } else {
                    // On login failed
                    store.dispatch('user/clearAccessToken')
                }
            } catch (e) {
                console.error(e)
            } finally {
                loading.value = false
            }
        })()

        return {
            loading,
            isLoggedIn,
            profile,
            cssVars,
            logout,
            navDrawer,
            navItems,
            smAndDown: displayParams.smAndDown
        }
    }
}
</script>

<style lang="sass" scoped>
header
    color: white
    z-index: 2
</style>
