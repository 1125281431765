import { createApp } from 'vue'
import { store, key } from '@/plugins/store'
import router from '@/plugins/router'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'

import '@/assets/styles/main.sass'


createApp(App)
    .use(store, key)
    .use(router)
    .use(vuetify)
    .mount('#app')