import EventEmitter from "./EventEmitter"

// Available events: update, finish
export default class Timer extends EventEmitter
{
    _intervalId = null
    _maxTime = 0
    _interval = 0
    currentTime = 0

    constructor (maxTime, interval = 1000) {
        
        super()

        if (!maxTime)
            throw new Error ('Can\'t create Timer without valid maxTime argument')

        if (typeof interval !== 'number')
            throw new Error ('Interval argument should be a type of number')

        if (interval <= 0)
            throw new Error ('Interval argument should be greater than zero')

        this._maxTime = maxTime
        this._interval = interval
    }

    updateMaxTime (value) {
        this._maxTime = value
    }
    
    start () {
        this.currentTime > 0 && this.stop()

        this.currentTime = this._maxTime
        
        this._intervalId = setInterval(() => {
            
            this.currentTime -= this._interval
            if (this.currentTime <= 0) {
                this.stop()
                this.fire('finish')
            } else {
                this.fire('update')
            }
        }, this._interval)
    }

    stop () {
        clearInterval(this._intervalId)
        this.currentTime = 0
        this._intervalId = null
    }
}