// import { auth, client } from 'node-shikimori'
import { auth, client } from '@/plugins/node-shikimori'
import { validateObject } from '@/helpers/common'
import userRateValidator from '@/validators/userRate'

const clientId = process.env.VUE_APP_SHIKIMORI_CLIENT_ID
const clientSecret = process.env.VUE_APP_SHIKIMORI_CLIENT_SECRET
const redirectURI = process.env.VUE_APP_SHIKIMORI_REDIRECT_URI
const shikimoriRealOrigin = process.env.VUE_APP_SHIKIMORI_REAL_ORIGIN
const shikimoriOrigin = process.env.VUE_APP_SHIKIMORI_ORIGIN
const authUri = `${shikimoriRealOrigin}/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectURI}&response_type=code&scope=user_rates`

const getShikimoriApi = () => {

    const getClient = (accessToken) => { 
        const shikimoriClient = client()
        accessToken && shikimoriClient.setAccessToken(accessToken)

        return shikimoriClient
    }

    const fetchAccessToken = async (code) => {
        if (typeof code !== 'string') {
            throw new Error('Given invalid argument.')
        }
        let error = null
        let response = null

        try {
            const { getAccessToken } = auth({ clientId, clientSecret, redirectURI })
            response = await getAccessToken(code)

            if (!response?.access_token) {
                throw new Error('Bad request. Access token missed.')
            }
        } catch (e) {
            console.error(e)
            error = e?.message
        }
        return { success: !error, error, data: response }
    }

    const refreshAccessToken = async (refreshToken) => {
        if (typeof refreshToken !== 'string') {
            throw new Error('Given invalid argument.')
        }
        let error = null
        let response = null

        try {
            const { refreshAccessToken: refreshFunction } = auth({ clientId, clientSecret, redirectURI })
            response = await refreshFunction(refreshToken)

            if (!response?.access_token) {
                throw new Error('Bad request. Access token missed.')
            }
        } catch (e) {
            console.error(e)
            error = e?.message
        }
        return { success: !error, error, data: response }
    }

    const runSearch = async (searchParams = {}) => {
        let result = null
        try {
            const _searchParams = {
                order: 'popularity',
                limit: 10,
                // search: '' - field for search key
            }
            Object.assign(_searchParams, searchParams)
            result = await getClient().animes.list(_searchParams)
        } catch (e) {
            console.error(e)
        }
        return result
    }

    const getByAnimeId = async (id) => {
        let result = null
        try {
            result = await getClient().animes.byId({ id })
        } catch (e) {
            console.error(e)
        }
        return result
    }

    const getRelated = async (id) => {
        let result = null
        try {
            result = await getClient().animes.related({ id })
        } catch (e) {
            console.error(e)
        }
        return result
    }

    const getUserRates = async (accessToken, user_id) => {
        let result = null
        try {
            result = await getClient(accessToken).userRates.list({ user_id })
        } catch (e) {
            console.error(e)
        }
        return result
    }

    const upsertAnimeRate = async (accessToken, params) => {

        validateObject(params, userRateValidator)

        let result = null
        try {
            params.target_type = 'Anime'
            result = await getClient(accessToken).userRates[params.id ? 'update' : 'create'](params)
        } catch (e) {
            console.error(e)
        }
        return result
    }

    const deleteRate = async (accessToken, id) => {

        try {
            await getClient(accessToken).userRates.destroy({ id })
        } catch (e) {
            console.error(e)
            return false
        }
        return true
    }

    return {
        authUri,
        clientId,
        clientSecret,
        shikimoriOrigin,
        getClient,
        fetchAccessToken,
        refreshAccessToken,
        runSearch,
        getByAnimeId,
        getRelated,
        getUserRates,
        upsertAnimeRate,
        deleteRate
    }
}

export default getShikimoriApi