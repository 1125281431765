import getShikimoriApi from '@/composables/getShikimoriApi'

export default {
    namespaced: true,
    state: {
        list: [],
        fetching: false
    },
    mutations: {
        setValue (state, [field, value]) {
            state[field] = value
        },
        updateListWithValue (state, rate) {
            const index = state.list.findIndex(item => item.id === rate.id)
            if (index < 0) {
                state.list.push(rate)
            } else {
                state.list[index] = rate
            }
        },
        deleteValueFromList (state, rateId) {
            state.list = state.list.filter(item => item.id !== rateId)
        }
    },
    getters: {
        sortedByStatus (state) {
            return Object.groupBy(state.list, item => item.status)
        }
    },
    actions: {
        // TODO: есть мысль, что стоит методы выполняющие здесь запросы декомпозировать.
        // Выполняющая запросы часть должна быть в композбл.
        // в сторедже нужно только то что изменяет состояние
        async fetch ({ commit, rootState }) {
            try {
                if (!rootState.user.profile?.id) {
                    throw new Error('Can\'t request rates without loged in.')
                }
                commit('setValue', ['fetching', true])
                const { getUserRates } = getShikimoriApi()
                const rates = await getUserRates(rootState.user.tokenInfo.access_token, rootState.user.profile.id)

                if (!rates) { throw new Error('Unexpected error occured when fetching rates.') }

                commit('setValue', ['list', rates])
            } catch (e) {
                console.error(e)
                return { success: false, error: e?.message }
            } finally {
                commit('setValue', ['fetching', false])
            }
            return { success: true, error: null }
        },

        async update ({ commit, rootState }, payload) {
            try {
                if (!rootState.user.profile?.id) {
                    throw new Error('Can\'t update rate without loged in.')
                }
                const { upsertAnimeRate } = getShikimoriApi()
                payload.user_id = rootState.user.profile.id
                
                const rate = await upsertAnimeRate(
                    rootState.user.tokenInfo.access_token,
                    payload
                )

                if (!rate) { throw new Error('Unexpected error occured when updating rate.') }

                commit('updateListWithValue', rate)

                return { success: true, data: rate, error: null }
            } catch (e) {
                console.error(e)
                return { success: false, error: e?.message }
            }
        },

        async delete ({ commit, rootState }, id) {
            try {
                if (!rootState.user.profile?.id) {
                    throw new Error('Can\'t update rate without loged in.')
                }
                const { deleteRate } = getShikimoriApi()

                const result = await deleteRate(
                    rootState.user.tokenInfo.access_token,
                    id
                )

                if (!result) { throw new Error('Unexpected error occured when deleting rate.') }

                commit('deleteValueFromList', id)
            } catch (e) {
                console.error(e)
                return { success: false, error: e?.message }
            }
            return { success: true, error: null }
        }
    }
}