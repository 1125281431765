
const debounce = (callee, timeoutMs) => {
    let lastCall = null
    let previousCall = null
    let lastCallTimer = null

    const cancel = () => {
        clearTimeout(lastCallTimer)
    }

    const execute = (...args) => {
        previousCall = lastCall
        lastCall = Date.now()
        if (previousCall && lastCall - previousCall <= timeoutMs) {
            cancel()
        }
        lastCallTimer = setTimeout(() => callee(...args), timeoutMs)
    }

    return { execute, cancel }
}

const delay = (timeoutMs = 0) => {
    return new Promise(resolve => setTimeout(resolve, timeoutMs))
}

// Метод валидации. Пример аргумента rules:
// [
//     { condition: () => !document, message: 'Constant document is unknown.' },
//     { condition: () => image?.constructor !== Image, message: 'The passed argument is not an instance of the Image class.' }
// ]
const validate = (rules) => {

    if (!Array.isArray(rules))
        throw new Error('Rules argument should be an Array type.')

    for (const rule of rules) {

        if (typeof rule.condition !== 'function')
            throw new Error('Each passed rule should has a "condition" field of function type.')

        if (typeof rule.message !== 'string')
            throw new Error('Each passed rule should has a "message" field of function type.')

        if (!rule.condition())
            throw new Error(rule.message)
    }
    return true
}

// Метод валидации полей объекта. Пример аргумента validateRules:
// {
//     user_id: {type: Number, required: true},
//     target_id: {type: Number, required: true},
//     id: {type: Number, required: false},
//     score: {type: Number, required: false},
//     status: {type: String, required: false}
// }
const validateObject = (object, validateRules) => {
    
    for (const validateRuleKey in validateRules) {

        const rule = validateRules[validateRuleKey]
        const objHasPropertyWithRuleKey = Object.hasOwnProperty.call(object, validateRuleKey)

        if (rule.required && !objHasPropertyWithRuleKey)
            throw new Error(`Missed required property with name ${validateRuleKey}`)
        
        if (
            objHasPropertyWithRuleKey &&
            !(object[validateRuleKey]?.constructor === rule.type)
        )
            throw new Error(`Property with name '${validateRuleKey}' has type '${typeof object[validateRuleKey]}'. Expected '${rule.type.name}'.`)
    }
    return true
}

// Метод удаляет из строки квадратные скобки и их содержимое
// Возвращает очищенную строку
const replaceCodes = (string) => {
    if (typeof string !== 'string') { return null }
    const regex = /\[.*?\]/gm
    return string.replace(regex, '')
}

export {
    debounce,
    delay,
    validate,
    validateObject,
    replaceCodes
}