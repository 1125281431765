import SpeechRecognition from './SpeechRecognition'

// Available events: commandDetected, onKeyCommandSet, onCommandsSet, commandChainCanceled
export default class SpeechToCommand extends SpeechRecognition
{

    commandChain = [] // Full commmands tree
    availableCommands = [] // Describes currently available commmands tree
    commandsHistory = []
    isProcessing = false // Flag indicates that key command was activated and manager listening other commands
    keyCommand = null // Command that activates other commands list
    isSubmoduleSupported = null
    _transctiptionHandler = null

    constructor () {
        super()
        this.isSubmoduleSupported = !!this.recognition
        
        if (this.isSubmoduleSupported) {
            this._transctiptionHandler = this._handleTranscription.bind(this)
            this.on('finalTranscript', this._transctiptionHandler)
        }
    }

    // This function should call when the object must be destroyed
    destroy () {
        this.purge()
        this.stop()
        this.recognition = null
    }

    /*
    commandsList = [
                {
                    key: 'найди аниме', handler: null,
                    returnToStart: false // If true, after execution will open initial command list
                    next: [
                        {
                            key: '*', handler: Function,
                            next: [{ key: 'отмена', handler: Function }]
                        },
                        { key: 'отмена', handler: Function }
                    ]
                }
            ]
    */
    addCommands (commandList) {

        if (!Array.isArray(commandList))
            throw new Error('Command list must be an array')

        for (const key in commandList) {
            if (
                typeof commandList[key] !== 'object' ||
                typeof commandList[key]?.key !== 'string'
            )
                throw new Error('Each command in given array must be a valid command object')
            
            const commandIndex = this.commandChain.findIndex(o => o.key === commandList[key].key)

            if (commandIndex > -1) {
                this.commandChain[commandIndex] = commandList[key]
                this.availableCommands = commandList[key].next
            }
            else
                this.commandChain.push(commandList[key])
        }
        this.fire('onCommandsSet', commandList)
    }

    removeRootCommand (key) {

        if (typeof key !== 'string')
            throw new Error ('First argument must be a type of string')

        this.commandChain = this.commandChain.filter(o => o.key !== key)
        this.availableCommands = this.commandChain
    }

    setKeyCommand (key) {
        if (typeof key !== 'string')
            throw new Error ('keyCommand must be a type of string')

        this.keyCommand = key
        this.fire('onKeyCommandSet', this.keyCommand)
    }

    simulateTranscription (value) {
        if (typeof value !== 'string')
            return false
        
        this.fire('finalTranscript', value)
        return true

    }

    _handleTranscription (transcription) {
        transcription = transcription.toLocaleLowerCase().trim()

        if (!this.isProcessing) {
            if (transcription.includes(this.keyCommand)) {

                this.isProcessing = true
                this.commandsHistory = []
                this.availableCommands = this.commandChain
                this.fire('commandDetected', this.keyCommand)
            }
            return false
        }

        let keyCommand = null

        for (const command of this.availableCommands) {
            
            if (transcription.includes(command.key)) {
                keyCommand = command.key
                break
            }
        }

        if (!keyCommand && this.availableCommands.map(o => o.key).includes('*')) {
            keyCommand = '*'
        }
        if (!keyCommand) return false
        // Excecute command
        const commandObject = this.availableCommands.find(o => o.key === keyCommand)
        commandObject?.handler?.({ transcription, commandsHistory: this.commandsHistory })
        this.commandsHistory.push(keyCommand)
        // Update avalable commands
        if (commandObject.returnToStart) {
            this.commandsHistory = []
            this.availableCommands = this.commandChain
        }
        else if (!Array.isArray(commandObject?.next))
            return this.cancelCommandChain()
        else
            this.availableCommands = commandObject.next
     
        this.fire('commandDetected', keyCommand)
        
        return true
    }

    cancelCommandChain () {
        this.isProcessing = false
        this.availableCommands = this.commandChain
        this.fire('commandChainCanceled')
    }
}