export default class EventEmitter
{
    eventsList = {}

    on (eventKey, callback) {
        
        if (!(eventKey in this.eventsList))
            this.eventsList[eventKey] = []

        if (typeof callback !== 'function')
            throw new Error('Event callback should be a function')

        this.eventsList[eventKey].push(callback)
    }

    off (eventKey, callback) {
        
        if (!Array.isArray(this.eventsList[eventKey])) return

        const callbackIndex = this.eventsList[eventKey]?.findIndex(c => c === callback)

        if (typeof callbackIndex === 'number' && callbackIndex < 0) return

        this.eventsList[eventKey].splice(callbackIndex, 1)
    }

    fire (eventKey) {

        if (!Array.isArray(this.eventsList[eventKey])) return

        for (const callback of this.eventsList[eventKey])
            callback?.(...Array.from(arguments).slice(1))
    }

    purge () {
        this.eventsList = {}
    }
}