// import '@mdi/font/scss/materialdesignicons.scss' // TODO: Doesnt work correctly, but it would be better to fix it. After that dont forget to remove remote link from index.html
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { ru } from 'vuetify/locale'

const vuetify = createVuetify({
  components,
  directives,
  lang: {
      locales: { ru },
      current: 'ru'
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})

export default vuetify