import { createStore } from 'vuex'
import user from './modules/user'
import rates from './modules/rates'
import speech_manager from './modules/speech_manager'
import snack_bar from './modules/snack_bar'

export const key = Symbol()

export const store = createStore({
  modules: {
    user,
    rates,
    speech_manager,
    snack_bar
  }
})