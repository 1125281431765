export default [
    { name: 'home', path: '/', component: () => import('@/views/HomePage.vue') },
    
    { name: 'login', meta: { layout: 'poor' }, path: '/login', component: () => import('@/views/LoginPage.vue') },
    
    { name: 'oauth', meta: { layout: 'poor' }, path: '/oauth', component: () => import('@/views/OauthPage.vue') },
    
    { name: 'about', path: '/about', component: () => import('@/views/AboutPage.vue') },

    { name: 'anime', path: '/anime/:id', component: () => import('@/views/AnimePage.vue') },
    
    { name: 'marathon', meta: { overflowHiddenHard: true }, path: '/marathon', component: () => import('@/views/MarathonPage.vue') },

    { name: 'watch-status', path: '/watch-status/:status', component: () => import('@/views/WatchStatusPage.vue') },

    // Errors
    { path: '/:catchAll(.*)', component: () => import('@/views/errors/404.vue') },
]