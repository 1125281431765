<template>
    <v-container>
        <h1 class="mb-5">Страница не доступна</h1>
        <p>Авторизуйтесь, чтобы получить доступ</p>
    </v-container>
</template>

<script>
export default {
    name: '404Page'
}
</script>