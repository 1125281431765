import getShikimoriApi from '@/composables/getShikimoriApi'

const localStorageKey = 'voice_animelist_'

export default {
    namespaced: true,
    state: {
        tokenInfo: null,
        profile: null
    },
    getters: {
        isLoggedIn (state) {
            return !!state.tokenInfo?.access_token
        },
        isTokenExpired (state) {
            const created_at = state.tokenInfo?.created_at
            const expires_in = state.tokenInfo?.expires_in
            if (!created_at || !expires_in) { return false }

            const expires_at = created_at + expires_in
            return parseInt(Date.now() / 1000) < expires_at
        }
    },
    mutations: {
        setValue (state, [field, value]) {
            state[field] = value
        }
    },
    actions: {
        saveTokenInfo (context, tokenInfo) {
            localStorage.setItem(`${localStorageKey}_token_info`, JSON.stringify(tokenInfo))
        },
        
        loadTokenInfo ({ commit }) {
            let tokenInfo = localStorage.getItem(`${localStorageKey}_token_info`)
            try {
                tokenInfo = tokenInfo ? JSON.parse(tokenInfo) : null
            } catch {
                tokenInfo = null
            }
            commit('setValue', ['tokenInfo', tokenInfo])
        },

        async fetchProfile ({ commit, state }) {
            try {
                const { getClient } = getShikimoriApi()
                const shikimoriClient = getClient(state.tokenInfo?.access_token || null)
                const profile = await shikimoriClient.users.whoami()
                
                commit('setValue', ['profile', profile || null])
            } catch (e) {
                console.error(e)
                return e?.message
            }
        },

        async requestAccessToken ({ dispatch }, code) {
            try {
                if (!code) {
                    throw new Error('Missed auth code as argument.')
                }
                const { fetchAccessToken } = getShikimoriApi()
                const { success, error, data } = await fetchAccessToken(code)
                
                if (!success) { throw new Error(error) }

                dispatch('saveTokenInfo', data)
            } catch (e) {
                console.error(e)
                return e?.message
            }
        },

        async refreshAccessToken ({ state, dispatch }) {
            try {
                if (!state.tokenInfo?.refresh_token) {
                    throw new Error('Wrong state to refresh access token.')
                }
                const { refreshAccessToken } = getShikimoriApi()
                const { success, error, data } = await refreshAccessToken(state.tokenInfo?.refresh_token)

                if (!success) { throw new Error(error) }

                dispatch('saveTokenInfo', data)
            } catch (e) {
                console.error(e)
                return e?.message
            }
        },

        async clearAccessToken ({ dispatch, commit }) {
            dispatch('saveTokenInfo', null)
            dispatch('loadTokenInfo')
            commit('setValue', ['tokenInfo', null])
        },

        async clearProfile ({ commit }) {
            commit('setValue', ['profile', null])
        }
    }
}