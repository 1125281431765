var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/constants.ts
var BASE_URL = process.env.VUE_APP_SHIKIMORI_ORIGIN;
var TOKEN_PATH = "/oauth/token";
var DEFAULT_USER_AGENT = "node-shikimori";
var MAX_CALLS_PER_SECOND = 5;
var MAX_CALLS_PER_MINUTE = 90;

// src/error.ts
var APIError = class extends Error {
  constructor(message, response) {
    super(message);
    this.message = message;
    this.response = response;
  }
};

// src/limitedRequest.ts
var request = async (path, options) => {
  var _a;
  const url = new URL(path, BASE_URL);
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new APIError("Bad response", response);
  }
  const text = await response.text();

  if (!text) return null;
  return (_a = JSON.parse(text)) != null ? _a : null;
};
var MILLISECONDS_IN_SECOND = 1e3;
var MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * 60;
var limitedRequest = (maxCallsPerSecond, maxCallsPerMinute) => {
  let secondFetchCount = 0;
  let minuteFetchCount = 0;
  let secondStart = Date.now();
  let minuteStart = Date.now();
  return (path, options) => {
    const now = Date.now();
    if (now - secondStart >= MILLISECONDS_IN_SECOND) {
      secondFetchCount = 0;
      secondStart = now;
    }
    if (now - minuteStart >= MILLISECONDS_IN_MINUTE) {
      minuteFetchCount = 0;
      minuteStart = now;
    }
    secondFetchCount++;
    minuteFetchCount++;
    if (secondFetchCount > maxCallsPerSecond) {
      throw new APIError("Maximum fetch requests per second exceeded", null);
    }
    if (minuteFetchCount > maxCallsPerMinute) {
      throw new APIError("Maximum fetch requests per minute exceeded", null);
    }
    return request(path, options);
  };
};

// src/apiProvider.ts
var httpMethods = (request2) => ({
  get: request2.bind(null, "GET"),
  post: request2.bind(null, "POST"),
  patch: request2.bind(null, "PATCH"),
  _delete: request2.bind(null, "DELETE")
});
var apiProvider = ({
  token,
  clientName = DEFAULT_USER_AGENT,
  maxCallsPerSecond = MAX_CALLS_PER_SECOND,
  maxCallsPerMinute = MAX_CALLS_PER_MINUTE
}) => {
  const request2 = limitedRequest(maxCallsPerSecond, maxCallsPerMinute);
  let accessToken = token;
  const apiRequest = async (type, endpoint, params) => {
    const shouldUseBodyParams = type !== "GET";
    let fullPath = `/api${endpoint}`;
    if (!shouldUseBodyParams) {
      const searchParams = new URLSearchParams(Object.entries(params));
      fullPath += `?${searchParams}`;
    }
    const headers = new Headers({
      "User-Agent": clientName
    });
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    if (shouldUseBodyParams && params) {
      headers.set("Content-Type", "application/json");
    }
    return request2(fullPath, {
      method: type,
      headers,
      body: shouldUseBodyParams && params ? JSON.stringify(params) : void 0
    });
  };
  const setAccessToken = (token2) => {
    accessToken = token2;
  };
  return [apiRequest, setAccessToken];
};

// src/endpoints/abuse-requests.ts
var abuseRequests = ({ post }) => {
  const offtopic = (params) => post("/v2/abuse_requests/offtopic", params);
  const review = (params) => post("/v2/abuse_requests/review", params);
  const abuse = (params) => post("/v2/abuse_requests/abuse", params);
  const spoiler = (params) => post("/v2/abuse_requests/spoiler", params);
  return {
    offtopic,
    review,
    abuse,
    spoiler
  };
};

// src/endpoints/achievements.ts
var achievements = (params) => {
  const { get } = params;
  const list = (params2) => get("/achievements", params2);
  return { list };
};

// src/endpoints/animes.ts
var animes = ({ get }) => {
  const list = (params) => get(`/animes`, params);
  const byId = ({ id }) => get(`/animes/${id}`, {});
  const roles = ({ id }) => get(`/animes/${id}/roles`, {});
  const similar = ({ id }) => get(`/animes/${id}/similar`, {});
  const related = ({ id }) => get(`/animes/${id}/related`, {});
  const screenshots = ({ id }) => get(`/animes/${id}/screenshots`, {});
  const franchise = ({ id }) => get(`/animes/${id}/franchise`, {});
  const externalLinks = ({ id }) => get(`/animes/${id}/external_links`, {});
  const topics2 = (_a) => {
    var _b = _a, { id } = _b, params = __objRest(_b, ["id"]);
    return get(`/animes/${id}/topics`, params);
  };
  return {
    list,
    byId,
    roles,
    similar,
    related,
    screenshots,
    franchise,
    externalLinks,
    topics: topics2
  };
};

// src/endpoints/appears.ts
var appears = ({ post }) => {
  const markAsRead = (params) => post("/appears", params);
  return { markAsRead };
};

// src/endpoints/bans.ts
var bans = ({ get }) => {
  const list = () => get("/bans", {});
  return { list };
};

// src/endpoints/calendars.ts
var calendars = ({ get }) => {
  const list = (params) => get("/calendar", params);
  return { list };
};

// src/endpoints/characters.ts
var characters = ({ get }) => {
  const byId = ({ id }) => get(`/characters/${id}`, {});
  const search = (params) => get(`/characters/search`, params);
  return { byId, search };
};

// src/endpoints/clubs.ts
var clubs = ({ get, post, patch }) => {
  const list = (params) => get("/clubs", params);
  const byId = ({ id }) => get(`/clubs/${id}`, {});
  const update = ({ id }) => patch(`/clubs/${id}`, {});
  const animes2 = (_a) => {
    var _b = _a, { id } = _b, params = __objRest(_b, ["id"]);
    return get(`/clubs/${id}/animes`, params);
  };
  const mangas2 = (_c) => {
    var _d = _c, { id } = _d, params = __objRest(_d, ["id"]);
    return get(`/clubs/${id}/mangas`, params);
  };
  const ranobe2 = (_e) => {
    var _f = _e, { id } = _f, params = __objRest(_f, ["id"]);
    return get(`/clubs/${id}/ranobe`, params);
  };
  const characters2 = (_g) => {
    var _h = _g, { id } = _h, params = __objRest(_h, ["id"]);
    return get(`/clubs/${id}/characters`, params);
  };
  const collections = (_i) => {
    var _j = _i, { id } = _j, params = __objRest(_j, ["id"]);
    return get(`/clubs/${id}/collections`, params);
  };
  const clubs2 = (_k) => {
    var _l = _k, { id } = _l, params = __objRest(_l, ["id"]);
    return get(`/clubs/${id}/clubs`, params);
  };
  const members = (_m) => {
    var _n = _m, { id } = _n, params = __objRest(_n, ["id"]);
    return get(`/clubs/${id}/members`, params);
  };
  const images = (_o) => {
    var _p = _o, { id } = _p, params = __objRest(_p, ["id"]);
    return get(`/clubs/${id}/images`, params);
  };
  const join = ({ id }) => post(`/clubs/${id}/join`, {});
  const leave = ({ id }) => post(`/clubs/${id}/leave`, {});
  return {
    list,
    byId,
    update,
    animes: animes2,
    mangas: mangas2,
    ranobe: ranobe2,
    characters: characters2,
    collections,
    clubs: clubs2,
    members,
    images,
    join,
    leave
  };
};

// src/endpoints/comments.ts
var comments = ({ get, post, patch, _delete }) => {
  const list = (params) => get("/comments", params);
  const byId = ({ id }) => get(`/comments/${id}`, {});
  const create = (params) => post("/comments", params);
  const update = (_a) => {
    var _b = _a, { id } = _b, params = __objRest(_b, ["id"]);
    return patch(`/comments/${id}`, params);
  };
  const destroy = ({ id }) => _delete(`/comments/${id}`, {});
  return {
    list,
    byId,
    create,
    update,
    destroy
  };
};

// src/endpoints/constants.ts
var constants = ({ get }) => {
  const anime = () => get("/constants/anime", {});
  const manga = () => get("/constants/manga", {});
  const userRate = () => get("/constants/user_rate", {});
  const club = () => get("/constants/club", {});
  const smileys = () => get("/constants/smileys ", {});
  return {
    anime,
    manga,
    userRate,
    club,
    smileys
  };
};

// src/endpoints/dialogs.ts
var dialogs = ({ get, _delete }) => {
  const list = () => get("/dialogs", {});
  const byId = ({ id }) => get(`/dialogs/${id}`, {});
  const destroy = ({ id }) => _delete(`/dialogs/${id}`, {});
  return {
    list,
    byId,
    destroy
  };
};

// src/endpoints/episode-notifications.ts
var episodeNotifications = ({ post }) => {
  const list = (params) => post("/v2/episode_notifications", params);
  return { list };
};

// src/endpoints/favorites.ts
var favorites = ({ get, post, _delete }) => {
  const create = ({
    linked_id,
    linked_type,
    kind
  }) => {
    const favoritesKind = kind ? `/${kind}` : "";
    return get(`/favorites/${linked_type}/${linked_id}${favoritesKind}`, {});
  };
  const destroy = ({
    linked_type,
    linked_id
  }) => _delete(`/favorites/${linked_type}/${linked_id}`, {});
  const reorder = (_a) => {
    var _b = _a, { id } = _b, params = __objRest(_b, ["id"]);
    return post(`/favorites/${id}/reorder`, params);
  };
  return {
    create,
    destroy,
    reorder
  };
};

// src/endpoints/forums.ts
var forums = ({ get }) => {
  const list = () => get("/forums", {});
  return { list };
};

// src/endpoints/friends.ts
var friends = ({ post, _delete }) => {
  const create = ({ id }) => post(`/friends/${id}`, {});
  const destroy = ({ id }) => _delete(`/friends/${id}`, {});
  return { create, destroy };
};

// src/endpoints/genres.ts
var genres = ({ get }) => {
  const list = () => get("/genre", {});
  return { list };
};

// src/endpoints/mangas.ts
var mangas = ({ get }) => {
  const list = (params) => get(`/animes`, params);
  const byId = ({ id }) => get(`/animes/${id}`, {});
  const roles = ({ id }) => get(`/animes/${id}/roles`, {});
  const similar = ({ id }) => get(`/animes/${id}/similar`, {});
  const related = ({ id }) => get(`/animes/${id}/relation`, {});
  const franchise = ({ id }) => get(`/animes/${id}/franchise`, {});
  const externalLinks = ({ id }) => get(`/animes/${id}/external_links`, {});
  const topics2 = (_a) => {
    var _b = _a, { id } = _b, params = __objRest(_b, ["id"]);
    return get(`/animes/${id}/topics`, params);
  };
  return {
    list,
    byId,
    roles,
    similar,
    related,
    franchise,
    externalLinks,
    topics: topics2
  };
};

// src/endpoints/people.ts
var people = ({ get }) => {
  const byId = ({ id }) => get(`/people/${id}`, {});
  const search = (params) => get("/people", params);
  return { byId, search };
};

// src/endpoints/publishers.ts
var publishers = ({ get }) => {
  const list = () => get("/publishers", {});
  return { list };
};

// src/endpoints/ranobe.ts
var ranobe = ({ get }) => {
  const list = (params) => get(`/animes`, params);
  const byId = ({ id }) => get(`/animes/${id}`, {});
  const roles = ({ id }) => get(`/animes/${id}/roles`, {});
  const similar = ({ id }) => get(`/animes/${id}/similar`, {});
  const related = ({ id }) => get(`/animes/${id}/relation`, {});
  const franchise = ({ id }) => get(`/animes/${id}/franchise`, {});
  const externalLinks = ({ id }) => get(`/animes/${id}/external_links`, {});
  const topics2 = (_a) => {
    var _b = _a, { id } = _b, params = __objRest(_b, ["id"]);
    return get(`/animes/${id}/topics`, params);
  };
  return {
    list,
    byId,
    roles,
    similar,
    related,
    franchise,
    externalLinks,
    topics: topics2
  };
};

// src/endpoints/reviews.ts
var reviews = ({ post, patch, _delete }) => {
  const create = (params) => post("/reviews", params);
  const update = (_a) => {
    var _b = _a, { id } = _b, params = __objRest(_b, ["id"]);
    return patch(`/reviews/${id}`, params);
  };
  const destroy = ({ id }) => _delete(`/reviews/${id}`, {});
  return {
    create,
    update,
    destroy
  };
};

// src/endpoints/stats.ts
var stats = ({ get }) => {
  const activeUsers = () => get("/stats/active_users", {});
  return { activeUsers };
};

// src/endpoints/studios.ts
var studios = ({ get }) => {
  const list = () => get("/studios", {});
  return { list };
};

// src/endpoints/styles.ts
var styles = ({ get, post, patch }) => {
  const byId = ({ id }) => get(`/styles/${id}`, {});
  const preview = (params) => post(`/styles/preview`, params);
  const create = (params) => post(`/styles`, params);
  const update = (_a) => {
    var _b = _a, { id } = _b, params = __objRest(_b, ["id"]);
    return patch(`/styles/${id}`, params);
  };
  return {
    byId,
    preview,
    create,
    update
  };
};

// src/endpoints/topic-ignores.ts
var topicIgnores = ({ post, _delete }) => {
  const ignore = ({ topic_id }) => post(`/v2/topics/${topic_id}/ignore`, {});
  const unignore = ({ topic_id }) => _delete(`/v2/topics/${topic_id}/ignore`, {});
  return { ignore, unignore };
};

// src/endpoints/topics.ts
var topics = ({ get, post, _delete }) => {
  const list = (params) => get(`/topics`, params);
  const updates = (params) => get(`/topics/updates`, params);
  const hot = (params) => get(`/topics/hot`, params);
  const byId = ({ id }) => get(`/topics/${id}`, {});
  const create = (params) => post(`/topics`, params);
  const update = (params) => post(`/topics`, params);
  const destroy = ({ id }) => _delete(`/topics/${id}`, {});
  return {
    list,
    updates,
    hot,
    byId,
    create,
    update,
    destroy
  };
};

// src/endpoints/user-ignores.ts
var userIgnores = ({ post, _delete }) => {
  const ignore = ({ user_id }) => post(`/v2/users/${user_id}/ignore`, {});
  const unignore = ({ user_id }) => _delete(`/v2/users/${user_id}/ignore`, {});
  return { ignore, unignore };
};

// src/endpoints/user-images.ts
var userImages = ({ post }) => {
  const create = (params) => post(`/user_images`, params);
  return { create };
};

// src/endpoints/user-rates.ts
var userRates = ({ get, post, patch, _delete }) => {
  const list = (params) => get(`/v2/user_rates/`, params);
  const byId = ({ id }) => get(`/v2/user_rates/${id}`, {});
  const create = (params) => post(`/v2/user_rates/`, params);
  const update = (_a) => {
    var _b = _a, { id } = _b, params = __objRest(_b, ["id"]);
    return patch(`/v2/user_rates/${id}`, params);
  };
  const increment = ({ id }) => post(`/v2/user_rates/${id}/increment`, {});
  const destroy = ({ id }) => _delete(`/v2/user_rates/${id}`, {});
  return {
    list,
    byId,
    create,
    update,
    increment,
    destroy
  };
};

// src/endpoints/users.ts
var users = ({ get }) => {
  const list = (params) => get(`/users`, params);
  const byId = (_a) => {
    var _b = _a, { id } = _b, params = __objRest(_b, ["id"]);
    return get(`/users/${id}`, params);
  };
  const info = ({ id }) => get(`/users/${id}/info`, {});
  const whoami = () => get(`/users/whoami`, {});
  const signOut = () => get(`/users/sign_out`, {});
  const friends2 = ({ id }) => get(`/users/${id}/friends`, {});
  const clubs2 = ({ id }) => get(`/users/${id}/clubs`, {});
  const animeRates = (_c) => {
    var _d = _c, { id } = _d, params = __objRest(_d, ["id"]);
    return get(`/users/${id}/anime_rates`, params);
  };
  const mangaRates = (_e) => {
    var _f = _e, { id } = _f, params = __objRest(_f, ["id"]);
    return get(`/users/${id}/manga_rates`, params);
  };
  const favourites = ({ id }) => get(`/users/${id}/favourites`, {});
  const messages = (_g) => {
    var _h = _g, { id } = _h, params = __objRest(_h, ["id"]);
    return get(`/users/${id}/messages`, params);
  };
  const unreadMessages = ({ id }) => get(`/users/${id}/unread_messages`, {});
  const history = (_i) => {
    var _j = _i, { id } = _j, params = __objRest(_j, ["id"]);
    return get(`/users/${id}/history`, params);
  };
  const bans2 = ({ id }) => get(`/users/${id}/bans`, {});
  return {
    list,
    byId,
    info,
    whoami,
    signOut,
    friends: friends2,
    clubs: clubs2,
    animeRates,
    mangaRates,
    favourites,
    messages,
    unreadMessages,
    history,
    bans: bans2
  };
};

// src/endpoints/videos.ts
var videos = ({ get, post, _delete }) => {
  const byId = ({ anime_id }) => get(`/animes/${anime_id}/videos`, {});
  const create = (_a) => {
    var _b = _a, { anime_id } = _b, params = __objRest(_b, ["anime_id"]);
    return post(`/animes/${anime_id}/videos`, params);
  };
  const destroy = ({ anime_id, video_id }) => _delete(`/animes/${anime_id}/videos/${video_id}`, {});
  return {
    byId,
    create,
    destroy
  };
};

// src/client.ts
var client = (options = {}) => {
  const [request2, setAccessToken] = apiProvider(options);
  const methods = httpMethods(request2);
  return {
    /**
     * Sets the access token for an OAuth2 clientName
     * @param token - The access token to set
     */
    setAccessToken,
    abuseRequests: abuseRequests(methods),
    achievements: achievements(methods),
    animes: animes(methods),
    appears: appears(methods),
    bans: bans(methods),
    calendars: calendars(methods),
    characters: characters(methods),
    clubs: clubs(methods),
    comments: comments(methods),
    constants: constants(methods),
    dialogs: dialogs(methods),
    episodeNotifications: episodeNotifications(methods),
    favorites: favorites(methods),
    forums: forums(methods),
    friends: friends(methods),
    genres: genres(methods),
    mangas: mangas(methods),
    people: people(methods),
    publishers: publishers(methods),
    ranobe: ranobe(methods),
    reviews: reviews(methods),
    stats: stats(methods),
    studios: studios(methods),
    styles: styles(methods),
    topicIgnores: topicIgnores(methods),
    topics: topics(methods),
    userIgnores: userIgnores(methods),
    userImages: userImages(methods),
    userRates: userRates(methods),
    users: users(methods),
    videos: videos(methods)
  };
};

// src/auth.ts
var auth = ({ clientName, clientId, clientSecret, redirectURI }) => {
  const authRequest = (body) => request(TOKEN_PATH, {
    method: "POST",
    headers: {
      "User-Agent": clientName != null ? clientName : DEFAULT_USER_AGENT
    },
    body
  });
  const getAccessToken = (authCode) => {
    const body = new URLSearchParams({
      "grant_type": "authorization_code",
      "client_id": clientId,
      "client_secret": clientSecret,
      "code": authCode,
      "redirect_uri": redirectURI != null ? redirectURI : "urn:ietf:wg:oauth:2.0:oob"
    });
    return authRequest(body);
  };
  const refreshAccessToken = (refreshToken) => {
    const body = new URLSearchParams({
      "grant_type": "refresh_token",
      "client_id": clientId,
      "client_secret": clientSecret,
      "refresh_token": refreshToken
    });
    return authRequest(body);
  };
  return { getAccessToken, refreshAccessToken };
};
export {
  APIError,
  BASE_URL,
  DEFAULT_USER_AGENT,
  MAX_CALLS_PER_MINUTE,
  MAX_CALLS_PER_SECOND,
  TOKEN_PATH,
  abuseRequests,
  achievements,
  animes,
  appears,
  auth,
  bans,
  calendars,
  characters,
  client,
  clubs,
  comments,
  constants,
  dialogs,
  episodeNotifications,
  favorites,
  forums,
  friends,
  genres,
  mangas,
  people,
  publishers,
  ranobe,
  reviews,
  stats,
  studios,
  styles,
  topicIgnores,
  topics,
  userIgnores,
  userImages,
  userRates,
  users,
  videos
};
