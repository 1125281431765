<template>
    <v-app :class="[ overflowHiddenClass ]">
        <component :is="layout">
            <error401-page v-if="isPrivatePage && !isLoggedIn" />
            <router-view v-else :key="fullPath" />
        </component>
    </v-app>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import PoorLayout from '@/layouts/PoorLayout.vue'
import { useStore } from 'vuex'
import { key } from '@/plugins/store'
import { useDisplay } from 'vuetify'
import Error401Page from '@/views/errors/401.vue'

export default {
  name: 'App',
  components: { DefaultLayout, PoorLayout, Error401Page },

  computed: {
    overflowHiddenClass () {
      return this.$route.meta.overflowHiddenHard ? 'overflow-hidden' : ''
    },
    layout () {
      return `${this.$route.meta.layout || 'default' }-layout`
    },
    fullPath () {
      return this.$router.currentRoute.value.fullPath
    },
    store () {
        return useStore(key)
    },
    isPrivatePage () {
        return ['marathon', 'watch-status'].includes(this.$router.currentRoute.value.name)
    },
    isLoggedIn () {
        return this.store?.getters['user/isLoggedIn']
    }
  },
  mounted () {
    const isTouchDevice = useDisplay()?.platform?.value.touch

    // Не тач устройство и распознавание голоса поддерживается
    if (!isTouchDevice && this.store.state.speech_manager.instance.isSubmoduleSupported) {
        this.store.dispatch('speech_manager/init', 'слушай')
        this.store.dispatch('speech_manager/addCommands', [{ key: 'отмена' }])
    }

    // Не тач устройство и распознавание голоса НЕ поддерживается
    if (!isTouchDevice && !this.store.state.speech_manager.instance.isSubmoduleSupported)
    this.store.dispatch('snack_bar/show', {type: 'error', text: 'Ваше браузер не поддерживает распознование голоса. Рекомендуем воспользоваться Google Chrome, если вам важна функция голосового управления.'})
  }
}
</script>
